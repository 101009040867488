<template>
  <ClientOnly>
    <GlobalHeaderMobile v-if="!!headerFooter.header && smAndDown" />
  </ClientOnly>
  <GlobalHeaderDesktop v-if="!!headerFooter.header && !smAndDown" />
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'

const { smAndDown } = useDisplay()

const headerFooterStore = useHeaderFooterStore()
const { headerFooter } = storeToRefs(headerFooterStore)
if (headerFooter.value.header === null) await headerFooterStore.getHeaderFooterData()
const { $loadTrustedShopsScript } = useNuxtApp()

onMounted(() => {
  $loadTrustedShopsScript()
})
</script>
